import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import DashboardWorkspace from "./DashboardWorkspace";
import {
  activeDashboardLayoutSelector,
  activeDashboardSelector, activeDashletsDataSelector,
  activeDashletsSelector
} from "selectors/dashboard/DashboardSelectors";
import {addDashletOnDashboard, onRemoveAccordionPanel, updateAccordion, removeDashlets, saveLayout, refreshDashboard} from "../../../actions/dashboardActions";
import { createAnomalyDashboard, handleLaodAomalyDashboardData } from 'dashlets/HLCChartDashlet/actions';
import { setRefreshDashboardInit } from 'reducers/dashboard/dashboardSlice';

class DashboardWorkspaceContainer extends Component {
  render() {
    return (
      <DashboardWorkspace
        {...this.props}
      />
    );
  }
}

DashboardWorkspaceContainer.propTypes = {
  //data
  isLeftMenuOpened: PropTypes.bool,
  activeDashboard: PropTypes.object,
  activeDashboardLayout: PropTypes.array,
  //activeDashboardLayout: PropTypes.object,
  activeDashlets: PropTypes.object,
  refreshDashboardInit: PropTypes.object,
  //...

  //func
  onChangeLayout: PropTypes.func,
  removeDashlets: PropTypes.func,
  onAddDashletHandler: PropTypes.func,
  onDeleteAccordionHandler: PropTypes.func,
  updateAccordionName: PropTypes.func,
  createAnomalyDashboard: PropTypes.func,
  handleLaodAomalyDashboardData: PropTypes.func,
  setRefreshDashboardInit: PropTypes.func,
  //...
};

const mapStateToProps = (state) => {
  return {
    activeDashboard: activeDashboardSelector(state),
    activeDashboardLayout: activeDashboardLayoutSelector(state),
    activeDashlets: activeDashletsSelector(state),
    refreshDashboardInit: state.dashboard.refreshDashboardInit
    //...
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeLayout: (dashboardId, layout) => {
      dispatch(saveLayout(dashboardId, layout));
    },
    removeDashlets: (dashlets) => {
      dispatch(removeDashlets(dashlets));
    },
    onAddDashletHandler: (dashboardId, accordionId) => {
      dispatch(addDashletOnDashboard(dashboardId, accordionId));
    },
    onDeleteAccordionHandler: (accordionId) => {
      dispatch(onRemoveAccordionPanel(accordionId));
    },
    updateAccordionName: (accordion) => {
      dispatch(updateAccordion(accordion));
    },
    createAnomalyDashboard: (dashboard) => {
      dispatch(createAnomalyDashboard(dashboard, false));
    }, 
    handleLaodAomalyDashboardData: (response) => {
      dispatch(handleLaodAomalyDashboardData(response));
    },
    refreshDashboard: (dashboardId) => {
      dispatch(refreshDashboard(dashboardId));
    },
    setRefreshDashboardInit: (dashboardData) => {
      dispatch(setRefreshDashboardInit(dashboardData));
    }   
    //...
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardWorkspaceContainer)