import React from "react";
import Integration from "types/model/settings/integration/Integration";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import IntegrationSection from "../IntegrationSection";
import UseAnomalySpikeSection from "./sections/UseAnomalySpikeSection";
import UseAnomalySpikeIntegration from "types/model/settings/UseAnomalySpikeIntegration";
import SnowflakeIntegration from "types/model/settings/integration/SnowflakeIntegration";
import SnowflakeSection from "./sections/SnowflakeSection";

interface UseAnomalySpikeIntegrationsProps {
    integrations: Record<string, Integration>;
    onSaveIntegration: (integration: Integration) => void;
}

const OtherIntegrations: React.FC<UseAnomalySpikeIntegrationsProps> = ({
    integrations,
    onSaveIntegration,
}) => (
    <>
        {integrations[IntegrationType.USE_ANOMALY_SPIKE] && (
            <IntegrationSection
                title={integrations[IntegrationType.USE_ANOMALY_SPIKE].title}
                content={
                    <UseAnomalySpikeSection
                        integration={
                            integrations[IntegrationType.USE_ANOMALY_SPIKE] as UseAnomalySpikeIntegration
                        }
                        onSave={onSaveIntegration}
                    />
                }
            />
        )}
    </>
);

export default OtherIntegrations;
