import React from "react";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {applySnowflakeTemplate, executeAssistant, refresh} from "../actions";
import {useDispatch} from "react-redux";
import useCommonDashletProperties from "hooks/dashlets/useCommonDashletProperties";
import GenerateSnowflakeFinOpsReportStompResponse from "../types/payload/GenerateSnowflakeFinOpsReportStompResponse";
import {
    SNOWFLAKE_DATABASE_REPORT_ASSISTANT_SUBSCRIPTION,
    SNOWFLAKE_DATABASE_REPORT_GENERATE_SUBSCRIPTION
} from "../webSocketClient";
import {setDashletData} from "reducers/dashboard/dashboardSlice";
import SnowflakeFinOpsReport from "../types/model/report/SnowflakeFinOpsReport";
import WarehousesSection from "./sections/WarehousesSection";
import ConsumptionSection from "./sections/ConsumptionSection";
import InventorySection from "./sections/InventorySection";
import RecommendationsSection from "./sections/RecommendationsSection";
import InformationDialog from "./dialogs/InformationDialog";
import SnowflakeFinOpsReportAssistantStompResponse from "../types/payload/SnowflakeFinOpsReportAssistantStompResponse";
import {toastr} from "react-redux-toastr";
import SnowflakeFinOpsTemplate from "../../../types/model/templates/finops/SnowflakeFinOpsTemplate";
import TemplateDialogs from "./dialogs/TemplateDialogs";
import DynamicTableSection from "./sections/DynamicTableSection";


interface SnowflakeReportDashletViewProps {
    dashlet: any;
}

const SnowflakeReportDashletView: React.FC<SnowflakeReportDashletViewProps> = ({
                                                                                   dashlet
                                                                               }) => {
    const dispatch = useDispatch();
    const [waitingAssistantResponse, setWaitingAssistantResponse] = React.useState<boolean>(false);
    const [informationModalOpen, setInformationModalOpen] = React.useState<boolean>(false);
    const [informationModalTitle, setInformationModalTitle] = React.useState<string>("");
    const [informationModalContent, setInformationModalContent] = React.useState<string>("");
    const dashletId = dashlet.id;
    const {
        data,
        backendKey,
        isStompClientConnected,
    } = useCommonDashletProperties(dashlet);

    const entityType = dashlet.settings.grouping;

    const handleSnowflakeDatabaseReportResult = (response: GenerateSnowflakeFinOpsReportStompResponse) => {
        // @ts-ignore
        dispatch(setDashletData(dashlet.id, {report: response.report}));
    }

    const executeAssistantClick = (name: string, entityType: string, assistanceType: string) => {
        if (waitingAssistantResponse) {
            return;
        }

        setWaitingAssistantResponse(true);
        dispatch(executeAssistant(
            backendKey,
            dashletId,
            dashlet.config.targetName,
            name,
            entityType,
            assistanceType,
            {
                ...dashlet.timeRange,
                ...dashlet.settings,
            },
        ));

        toastr.info("Assistant", "Waiting for Assistant...");
    }

    const handleAssistantResponse = (response: SnowflakeFinOpsReportAssistantStompResponse) => {
        console.log("Assistant response: ", response);
        const {
            hasErrors,
            errorMessage,
            entityName,
            entityType,
            assistanceType,
            result,
        } = response;
        if (!hasErrors) {
            setInformationModalTitle(`${entityType} ${assistanceType} - ${entityName}`);
            setInformationModalContent(result);
            setInformationModalOpen(true);
        } else {
            toastr.error("Assistant", `Unable to get Insight: ${errorMessage}`);
        }
        setWaitingAssistantResponse(false);
    }

    const handleLoadTemplate = (template: SnowflakeFinOpsTemplate) =>
        dispatch(applySnowflakeTemplate(dashletId, template));

    const report: SnowflakeFinOpsReport = data?.report;

    return (
        <>
            {report && (
                <>
                    <WarehousesSection title={`${entityType}s`}
                                       entityType={entityType}
                                       data={report?.warehousesSection}
                    />
                    <ConsumptionSection data={report?.consumptionSection}/>
                    {entityType === "Warehouse" && <>
                        <InventorySection title="Inventory" data={report?.inventorySection}/>
                        <RecommendationsSection
                            title="Recommendations"
                            data={report?.recommendationsSection}
                            showRecommendations={executeAssistantClick}
                            showObservations={executeAssistantClick}
                        />
                    </>
                    }

                    {report?.sections.map((sectionData, index) => (
                        <DynamicTableSection
                            key={`dynamic-table-section-${sectionData?.name}`}
                            entityType={entityType}
                            data={sectionData}
                            showRecommendations={executeAssistantClick}
                            showObservations={executeAssistantClick}
                        />
                    ))}
                </>
            )}
            <TemplateDialogs dashletId={dashletId} onLoadTemplate={handleLoadTemplate}/>
            {isStompClientConnected && (
                <HLCUserSessionWebSocketSubscription
                    key={`hlc-ws-subscr-snowflake-report-dashlet-${dashletId}`}
                    subscriptionDestination={SNOWFLAKE_DATABASE_REPORT_GENERATE_SUBSCRIPTION}
                    dashletId={dashletId}
                    handleMessage={handleSnowflakeDatabaseReportResult}
                    onSubscribeCallback={() => dispatch(refresh(dashlet))}
                />
            )}
            {isStompClientConnected && (
                <HLCUserSessionWebSocketSubscription
                    key={`hlc-ws-subscr-snowflake-report-dashlet-assistant-${dashletId}`}
                    subscriptionDestination={SNOWFLAKE_DATABASE_REPORT_ASSISTANT_SUBSCRIPTION}
                    dashletId={dashletId}
                    handleMessage={handleAssistantResponse}
                />
            )}
            <InformationDialog
                open={informationModalOpen}
                onClose={() => {
                    setInformationModalOpen(false)
                }}
                title={informationModalTitle}
                content={informationModalContent}
            />
        </>
    );
};

export default SnowflakeReportDashletView;
