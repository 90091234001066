import {publish} from 'api/websocket/hlc/hlcCommonWebSocketClient';

import {
    WEB_SOCKET_ACTION_PREFIX,
    WEB_SOCKET_SUBSCR_PREFIX
} from "constants/webSocketConstants";
import SaveTemplateStompFrame from "types/payload/templates/SaveTemplateStompFrame";
import ListTemplateNamesStompFrame from "types/payload/templates/ListTemplateNamesStompFrame";
import LoadTemplateStompFrame from "types/payload/templates/LoadTemplateStompFrame";

//
const TEMPLATES_LIST_TEMPLATE_NAMES_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.templates.names.list.execute`;
export const TEMPLATES_LIST_TEMPLATE_NAMES_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.templates.names.list.result`;

export const listTemplateNames = (
    appState: any,
    backendKey: string,
    frame: ListTemplateNamesStompFrame
) => publish(appState, backendKey, TEMPLATES_LIST_TEMPLATE_NAMES_ACTION, frame);


//
const TEMPLATES_SAVE_TEMPLATE_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.templates.save.execute`;
export const TEMPLATES_SAVE_TEMPLATE_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.templates.save.result`;

export const saveSnowflakeFinOpsReportTemplate = (appState: any, backendKey: string, frame: SaveTemplateStompFrame) =>
    publish(appState, backendKey, TEMPLATES_SAVE_TEMPLATE_ACTION, frame);

//
const TEMPLATES_LOAD_TEMPLATE_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.templates.load.execute`;
export const TEMPLATES_LOAD_TEMPLATE_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.templates.load.result`;

export const loadTemplate = (appState: any, backendKey: string, frame: LoadTemplateStompFrame) =>
    publish(appState, backendKey, TEMPLATES_LOAD_TEMPLATE_ACTION, frame);
