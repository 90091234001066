import React from "react";
import BooleanTableCell from "./BooleanTableCell";
import DecimalCurrencyTableCell from "./DecimalCurrencyTableCell";
import DecimalNumberTableCell from "./DecimalNumberTableCell";
import NumberTableCell from "./NumberTableCell";
import StringTableCell from "./StringTableCell";

interface GenericTableCellProps {
    type: string;
    value: string | number;
}

const CELL_COMPONENTS = {
    "boolean": BooleanTableCell,
    "currency": DecimalCurrencyTableCell,
    "decimal": DecimalNumberTableCell,
    "number": NumberTableCell,
    "string": StringTableCell,
}

const GenericTableCell: React.FC<GenericTableCellProps> = ({ type, value }) => {
    // @ts-ignore
    let TableCellComponent = CELL_COMPONENTS[type];
    if (!TableCellComponent) {
        TableCellComponent = StringTableCell;
    }

    return <TableCellComponent>{value}</TableCellComponent>;
};

export default GenericTableCell;
