import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface StringTableCellProps {
    children: string | null;
}

const useStyles = makeStyles({
    tableCell: {
        textAlign: "left",
        // fontSize: "0.6vw",
        fontSize: "12px",
    }
});

const StringTableCell: React.FC<StringTableCellProps> = ({ children }) => {
    const classes = useStyles();

    return <TableCell className={classes.tableCell}>{children != null ? children : "N/A"}</TableCell>;
};

export default StringTableCell;
