enum TargetDatabaseType {
    MySQL = "MySQL",
    Oracle = "Oracle",
    IBM_DB2 = "IBM DB2",
    Microsoft_SQL_Server = "Microsoft SQL Server",
    PostgreSQL = "PostgreSQL",
    Sybase = "Sybase",
    MongoDB = "MongoDB",
    Cassandra = "Cassandra",
    SnowFlake = "SnowFlake",
  }
  
  export default TargetDatabaseType;
  