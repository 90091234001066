import React from "react";
import IconButton from "@material-ui/core/IconButton";
import FolderIcon from "@material-ui/icons/Folder";
import {Theme, Tooltip, WithStyles} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {openLoadTemplateDialog} from "../../actions";

const styles = (theme: Theme) => ({
    iconButton: {
        "&:hover": {
            backgroundColor: '#fafafa',
        },
        paddingTop: '6px',
    },
    iconButtonDisable: {
        display: 'none',
    },
    icon: {
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.text.primary,
        },
        fontSize: '0.875rem',
    },
});

interface LoadTemplateButtonProps extends WithStyles<typeof styles> {
    dashletId: number;
}

const LoadTemplateButton: React.FC<LoadTemplateButtonProps> = ({
                                                                   classes,
                                                                   dashletId,
                                                               }) => {
    const dispatch = useDispatch();

    const handleClick = () => dispatch(openLoadTemplateDialog(dashletId));

    return (
        <Tooltip
            key={`dashlet-${dashletId}-load-template-button`}
            title="Load Tempalate"
        >
            <IconButton
                aria-label="Load Template"
                size="small"
                className={classes.iconButton}
                onClick={handleClick}
            >
                <FolderIcon
                    className={classes.icon}
                    fontSize="small"
                />
            </IconButton>
        </Tooltip>
    );
};

export default withStyles(styles)(LoadTemplateButton);
