import {selectHLCStompClient} from 'reducers/webSocketSlice';
import {
    BACKEND_KEY_HEADER_NAME,
    USER_SECRET,
    USER_SESSION_ID_HEADER_NAME,
    WEBSOCKET_USER_CLIENT_HEADER
} from 'constants/webSocketConstants';
import {getUserSessionId} from "actions/userInfoActions";
import { selectUserSecret } from 'reducers/userInfoSlice';

export const publish = (appState: any, backendKey: string, destination: string, payload: any, userSecret?: string) => {

    if (!appState) {
        console.error("publish: 'appState' is null or undefined.");
        return;
    }

    const stompClient = selectHLCStompClient(appState);

    if (!stompClient) {
        console.error("Can not connect to HLC - HLC StompClient is null");
        console.error("Can not connect publish to HLC [" + destination + "]");
        console.error("Current appState:", appState);
        return;
    }

    if (!stompClient.connected) {
        console.error(`Can not publish to ${destination}: WebSocket to HLC is not connected`);
        return;
    }

    stompClient.publish({
        destination: destination,
        body: JSON.stringify(payload || {}),
        headers: {
            [BACKEND_KEY_HEADER_NAME]: backendKey,
            [USER_SESSION_ID_HEADER_NAME]: getUserSessionId(),
            [USER_SECRET]: userSecret ? userSecret : selectUserSecret(appState),
            ...WEBSOCKET_USER_CLIENT_HEADER
        }
    });
}
