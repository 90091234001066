import Integration from "types/model/settings/integration/Integration";
import React, { useEffect } from "react";
import UseAnomalySpikeIntegration from "types/model/settings/UseAnomalySpikeIntegration";

function useIntegrationSectionState(initialIntegration: Integration) {
    const [integration, setIntegration]
        = React.useState<Integration>(initialIntegration);
    useEffect(() => {
        setIntegration(initialIntegration)
    }, [initialIntegration]);

    const handleChangeProperty = (propertyName: string, propertyValue: any) => {
        setIntegration({
            ...integration,
            [propertyName]: propertyValue
        })
    };

    const handleEnabledToggle = () => {
        setIntegration({
            ...integration,
            enabled: !integration.enabled
        });
    };

    return { integration, handleChangeProperty, handleEnabledToggle}
}

export default useIntegrationSectionState;
