import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

interface SectionHeaderProps {
    children: React.ReactNode;
}

const useStyles = makeStyles({
    sectionHeader: {
        float: "left",
        fontSize: "14px",
        marginBottom: "4px"
    }
});

const SectionHeader: React.FC<SectionHeaderProps> = ({children}) => {
    const classes = useStyles();

    return <Typography className={classes.sectionHeader}>{children}</Typography>;
}

export default SectionHeader;