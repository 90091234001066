import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {Scrollbars} from "react-custom-scrollbars";

interface InformationDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    content: string;
}

const InformationDialog: React.FC<InformationDialogProps> = ({
                                                               open,
                                                               onClose,
                                                               title,
                                                               content
                                                           }) => {

    React.useEffect(() => {}, [open]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle disableTypography>
                <Typography variant="body1">{title}</Typography>
                <IconButton
                    // size="small"
                    aria-label="close"
                    onClick={onClose}
                    style={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Scrollbars style={{width: "100%", height: "100%", minHeight: "600px"}}>
                    {/*@ts-ignore*/}
                    <pre style={{"text-wrap": "balance"}}>{content}</pre>
                </Scrollbars>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InformationDialog;