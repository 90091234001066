import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

interface ActionTableCellProps {
    title: string;
    onClick: () => void;
}

const useStyles = makeStyles({
    tableCell: {
        textAlign: "center",
        fontSize: "0.6vw",
    },
    button: {
        cursor: "pointer",
        fontSize: "12px",
        // fontSize: "0.8vw",
        fontWeight: "bold",
        color: "rgb(124, 181, 236)",
        textTransform: "none",
        padding: "3px 4px"
    }
});

const ActionTableCell: React.FC<ActionTableCellProps> = ({ title, onClick }) => {
    const classes = useStyles();

    return (
        <TableCell className={classes.tableCell}>
            <Button className={classes.button} onClick={onClick}>{title}</Button>
        </TableCell>
    );
};

export default ActionTableCell;
