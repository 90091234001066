import SnowflakeReportDashletView from "./components/SnowflakeReportDashletView";
import SnowflakeReportDashletCreate from "./components/SnowflakeReportDashletCreate";
import {buildDashletObject, refresh, validateConfig} from "./actions";
import SnowflakeReportDashletTimeRange from "./components/SnowflakeReportDashletTimeRange";
import SnowflakeReportDashletSettings from "./components/SnowflakeReportDashletSettings";
import SaveTemplateButton from "./components/toolbar/SaveTemplateButton";
import LoadTemplateButton from "./components/toolbar/LoadTemplateButton";


const DASHLET_TYPE_NAME = 'SNOWFLAKE_REPORT_DASHLET_TYPE';

export const SNOWFLAKE_REPORT_DASHLET_CONFIG = {
    [DASHLET_TYPE_NAME]: {
        typeName: DASHLET_TYPE_NAME,
        typeLabel: 'Snowflake FinOps Report',
        viewComponent: SnowflakeReportDashletView,
        createFormComponent: SnowflakeReportDashletCreate,
        editConfigFormComponent: null,

        settingsComponent: SnowflakeReportDashletSettings,
        openTemplateAsChartComponent: null,
        timeRangeComponent: SnowflakeReportDashletTimeRange,
        validateConfigFunc: validateConfig,
        loadOptionsAction: null,
        refreshAction: refresh,
        buildDashletObjectFunc: buildDashletObject,
        toolbarActionsComponents: [
            LoadTemplateButton,
            SaveTemplateButton
        ]
    }
}