import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import CancelButton from "components/common/Button/CancelButton";
import SubmitButton from "components/common/Button/SubmitButton";
import Select from "components/common/Select/Select";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import TimeRangeTab from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTab";
import TimeRangeTabPanel from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabPanel";
import TimeRangeTabs from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabs";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {Label} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import {Scrollbars} from "react-custom-scrollbars";

const styles = theme => ({
    root: {
        maxWidth: 520,
    },
    appBar: {
        backgroundColor: '#fff',
        boxShadow: 'none',
    },
    // formLabel: {
    //   padding: '0.4rem 0rem',
    //   color: '#16191F',
    // },
    boxWrapper: {
        display: 'inline-flex',
        marginRight: '8px',
        height: '1rem'
    },
    formControl: {
        margin: theme.spacing(1),
    },
    formControlLabel: {
        margin: theme.spacing(1),
        minWidth: '40px'
    },
    formControlLabelLong: {
        margin: theme.spacing(1),
        minWidth: '160px'
    },
    formControlInput: {
        margin: theme.spacing(1),
        minWidth: '60px',
    },
    formControlInputLong: {
        margin: theme.spacing(1),
        minWidth: '168px',
    },
    formControlSelect: {
        margin: theme.spacing(1),
        minWidth: '92px',
    },
    formControlDatetime: {
        margin: theme.spacing(1),
        width: '220px',
        minWidth: '160px',
    },
    footer: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    grow: {
        flexGrow: 1,
    },
    switchRoot: {
        marginLeft: 0,
        // flexDirection: 'row-reverse'
    },
    switchLabel: {
        minWidth: 100,
        marginRight: 8,
    },
    groupBySelect: {
        minWidth: 200,
        float: 'right',
        paddingRight: '4px',
    },
});


class HeatmapDashletSettings extends Component {
    // const hasFinanceRole =

    handleChangeInput = (event) => {
        this.props.handleChangeProperty(event.target.name, event.target.value);
    };

    toggle = (event) => {
        const name = event.target.name;
        this.props.handleChangeProperty(name, !this.props.settings[name]);
    };


    render() {
        const {
            classes,
            settings: {
                displayFinancialInformation,
                filterTargetName,
                filterDatabasePlatform,
                filterSpikeValue,
                isHideDisconnectedTargets
            },
            hasFinanceRole,
            handleConfirm,
            handleClose
        } = this.props;

        return (
            <Paper className={classes.root}>
                <AppBar position="relative" className={classes.appBar} component="div">
                    <TimeRangeTabs value="Filters">
                        <TimeRangeTab
                            label="Filters"
                            value="Filters"
                        />
                    </TimeRangeTabs>
                </AppBar>

                <Scrollbars style={{width: '52vh', height: '44vh', marginRight: 5}}>
                    <TimeRangeTabPanel>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center">

                            {hasFinanceRole && <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={displayFinancialInformation}
                                                     onChange={this.toggle}
                                                     name="displayFinancialInformation"
                                    />}
                                    label="Dispay Financial Information"
                                    classes={{root: classes.switchRoot, label: classes.switchLabel}}
                                />
                            </Grid>}

                            <Grid item xs={3}>
                                <FormLabel className={classes.formLabel}>
                                    Target Name
                                </FormLabel>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    name="filterTargetName"
                                    onChange={this.handleChangeInput}
                                    fullWidth
                                    value={filterTargetName}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <FormLabel className={classes.formLabel}>
                                    Database Type
                                </FormLabel>
                            </Grid>
                            <Grid item xs={9}>
                                <Select value={filterDatabasePlatform} name="filterDatabasePlatform"
                                        onChange={this.handleChangeInput} className={classes.groupBySelect}>
                                    <MenuItem value="">None</MenuItem>
                                    <MenuItem value="MySQL">MySQL</MenuItem>
                                    <MenuItem value="Oracle">Oracle</MenuItem>
                                    <MenuItem value="Microsoft SQL Server">Microsoft SQL Server</MenuItem>
                                    <MenuItem value="PostgreSQL">PostgreSQL</MenuItem>
                                    <MenuItem value="Sybase">Sybase</MenuItem>
                                    <MenuItem value="MongoDB">MongoDB</MenuItem>
                                    <MenuItem value="Cassandra">Cassandra</MenuItem>
                                </Select>
                            </Grid>

                            <Grid item xs={3}>
                                <FormLabel className={classes.formLabel}>
                                    Spike Value
                                </FormLabel>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    name="filterSpikeValue"
                                    onChange={this.handleChangeInput}
                                    fullWidth
                                    type="number"
                                    value={filterSpikeValue}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Switch checked={isHideDisconnectedTargets}
                                                     onChange={this.toggle}
                                                     name="isHideDisconnectedTargets"
                                    />}
                                    label="Hide Disconneccted Targets"
                                    classes={{root: classes.switchRoot, label: classes.switchLabel}}
                                />
                            </Grid>

                        </Grid>

                    </TimeRangeTabPanel>
                </Scrollbars>
                <Divider/>

                <div className={classes.footer}>
                    <div className={classes.grow}/>
                    <CancelButton onClick={handleClose}>Cancel</CancelButton>
                    <SubmitButton onClick={handleConfirm}>Apply</SubmitButton>
                </div>
            </Paper>
        );
    }
}

HeatmapDashletSettings.propTypes = {
    //data
    classes: PropTypes.object.isRequired,
    settings: PropTypes.object,
    errors: PropTypes.object,
    isLocked: PropTypes.bool,
    open: PropTypes.bool,
    hasFinanceRole: PropTypes.bool,

    //func
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleChangeProperty: PropTypes.func,
};

export default withStyles(styles)(HeatmapDashletSettings);
