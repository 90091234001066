import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectDashletSettings} from "reducers/dashboard/dashboardSlice";
import {updateDashletSettings} from "actions/dashboard/dashletActions";
import Paper from "@material-ui/core/Paper";
import {Theme, WithStyles} from "@material-ui/core";
import TimeRangeTabPanel from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabPanel";
import Divider from "@material-ui/core/Divider";
import CancelButton from "components/common/Button/CancelButton";
import SubmitButton from "components/common/Button/SubmitButton";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Select from "components/common/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@material-ui/core/FormGroup";
import {withStyles} from "@material-ui/core/styles";

const styles = (theme: Theme) => ({
    root: {},
    appBar: {
        backgroundColor: '#fff',
        boxShadow: 'none',
    },
    formLabel: {
        padding: '0.4rem 0rem',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    formControlLabel: {
        margin: theme.spacing(1),
        minWidth: '40px'
    },
    formControlInput: {
        margin: theme.spacing(1),
        minWidth: '60px',
    },
    formControlSelect: {
        margin: theme.spacing(1),
        minWidth: '120px',
    },
    formControlFullWidthSelect: {
        margin: theme.spacing(1),
        width: '192px',
        minWidth: '160px',
    },
    formControlDatetime: {
        margin: theme.spacing(1),
        width: '192px',
        minWidth: '160px',
    },
    footer: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    grow: {
        flexGrow: 1,
    },
    switchRoot: {
        marginLeft: 0,
    },
    switchLabel: {
        marginLeft: 8,
    },
});

interface SnowflakeReportDashletSettingsProps extends WithStyles<typeof styles> {
    dashletId: number;
    backendKey: string;
    close: () => void;
}

const SnowflakeReportDashletSettings: React.FC<SnowflakeReportDashletSettingsProps> = ({
                                                                                      classes,
                                                                                      dashletId,
                                                                                      backendKey,
                                                                                      close
                                                                                  }) => {
    const dispatch = useDispatch();
    const initialSettings = useSelector(state => selectDashletSettings(state, dashletId));
    const [settings, setSettings] = React.useState(initialSettings);

    const { grouping } = settings;

    const changeProperty = (name: string, value: any) => setSettings({
        ...settings,
        [name]: value
    });

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeProperty(event.target.name, event.target.value);
    };

    const handleCancel = () => close();

    const handleSubmit = () => {
        dispatch(updateDashletSettings(dashletId, settings));
        close();
    }

    return (
        <>
            <Paper className={classes.root}>
                {/* @ts-ignore */}
                <TimeRangeTabPanel>
                    <FormGroup row>
                        <FormControl className={classes.formControlLabel}>
                            <Typography component="div" className={classes.formLabel}>
                                Grouping
                            </Typography>
                        </FormControl>
                        <FormControl className={classes.formControlSelect}>
                            <Select
                                name="grouping"
                                value={grouping}
                                onChange={handleChangeInput}
                            >
                                <MenuItem value="Warehouse">Warehouse</MenuItem>
                                <MenuItem value="Database">Database</MenuItem>
                                <MenuItem value="Schema">Schema</MenuItem>
                                <MenuItem value="User">User</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                </TimeRangeTabPanel>

                <Divider />

                <div className={classes.footer}>
                    <div className={classes.grow}/>
                    {/* @ts-ignore */}
                    <CancelButton onClick={handleCancel}>Cancel</CancelButton>
                    {/* @ts-ignore */}
                    <SubmitButton onClick={handleSubmit}>Apply</SubmitButton>
                </div>
            </Paper>
        </>
    );
};

//export default SnowflakeReportDashletSettings;
export default withStyles(styles)(SnowflakeReportDashletSettings);
