import React from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "components/common/TextField/TextField";
import GenAIIntegrationConfigProps from "./GenAIIntegrationConfigProps.types";

const OpenAIGenAIIntegrationConfig: React.FC<GenAIIntegrationConfigProps> = ({
                                                                                       integration,
                                                                                       handleChangeProperty,
                                                                                       errors
                                                                                   }) => {
    const {enabled, openAIApiKey, openAIModel} = integration;

    return (
        <>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="apiKey">API Key</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        disabled={!enabled}
                        name="openAIApiKey"
                        fullWidth
                        textAlign="left"
                        value={openAIApiKey}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                    {enabled && errors.openAIApiKey &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.openAIApiKey}</div>}
                </Grid>
            </Grid>
            <Grid item container spacing={2} xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={2} style={{textAlign: "left"}}>
                    <InputLabel htmlFor="modalName">Model Name</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        disabled={!enabled}
                        name="openAIModel"
                        fullWidth
                        textAlign="left"
                        value={openAIModel || ""}
                        onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                    />
                    {enabled && errors.openAIModel &&
                        <div style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.openAIModel}</div>}
                </Grid>
            </Grid>
        </>
    );
};

export default OpenAIGenAIIntegrationConfig;
