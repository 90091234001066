import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import LogRocket from "logrocket";
import UserRoles from "types/model/user/UserRoles";
import UserInfo from "types/model/user/UserInfo";

interface UserInfoState {
    currentUserInfo: UserInfo | null;
    isLoading: boolean;
}

const initialState: UserInfoState = {
    currentUserInfo: null,
    isLoading: false
};

const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setCurrentUserInfo(state, action: PayloadAction<UserInfo>) {
            const userInfo = action.payload;
            LogRocket.identify(String(userInfo.id), {
                name: userInfo.name,
                email: userInfo.username,

                // Add your own custom user variables here, ie:
                // subscriptionType: 'pro'
            });
            state.currentUserInfo = userInfo;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    }
});

export const {
    setCurrentUserInfo,
    setIsLoading
} = userInfoSlice.actions;

export default userInfoSlice.reducer;

export const selectHasFinanceRole = (state: any) =>
    ((state.userInfo?.currentUserInfo && state.userInfo?.currentUserInfo?.roles) || []).indexOf(UserRoles.ROLE_FINANCE) !== -1;

export const selectUserSecret = (state: any) => state.userInfo.currentUserInfo?.secret

export const selectBackendCurrentUserInfo = (state: any) => state.userInfo.currentUserInfo;