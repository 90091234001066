import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

interface HeaderValueProps {
    children: React.ReactNode;
}

const useStyles = makeStyles({
    headerValue: {
        fontSize: "12px",
        fontWeight: "bold"
    }
});

const HeaderValue: React.FC<HeaderValueProps> = ({children}) => {
    const classes = useStyles();

    return <Typography className={classes.headerValue}>{children}</Typography>;
};

export default HeaderValue;
