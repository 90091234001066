import React from "react";
import Integration from "types/model/settings/integration/Integration";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import IntegrationSection from "../IntegrationSection";
import SnowflakeIntegration from "types/model/settings/integration/SnowflakeIntegration";
import SnowflakeSection from "./sections/SnowflakeSection";

interface UseFinOpsIntegrationsProps {
    integrations: Record<string, Integration>;
    onSaveIntegration: (integration: Integration) => void;
}


const FinOpsIntegrations: React.FC<UseFinOpsIntegrationsProps> = ({
    integrations,
    onSaveIntegration,
}) => (
    <>
        {integrations[IntegrationType.SNOWFLAKE] && (
            <IntegrationSection
                title={integrations[IntegrationType.SNOWFLAKE].title}
                content={
                    <SnowflakeSection
                        integration={
                            integrations[IntegrationType.SNOWFLAKE] as SnowflakeIntegration
                        }
                        onSave={onSaveIntegration}
                    />
                }
            />
        )}
    </>
);

export default FinOpsIntegrations;
