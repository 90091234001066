import React from "react";
import GenAIIntegrationConfigProps from "./GenAIIntegrationConfigProps.types";

const EmptyGenAIIntegrationConfig: React.FC<GenAIIntegrationConfigProps> = () => {

    // empty config

    return null;
};

export default EmptyGenAIIntegrationConfig;

