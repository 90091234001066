import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

interface HeaderLabelProps {
    children: React.ReactNode;
}

const useStyles = makeStyles({
    headerLabel: {
        fontSize: "12px",
        fontWeight: "bold"
    }
});

const HeaderLabel: React.FC<HeaderLabelProps> = ({children}) => {
    const classes = useStyles();

    return <Typography className={classes.headerLabel}>{children}</Typography>;
}

export default HeaderLabel;
