import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface DashletsStorageState {
    dashlets: Record<number, any> | {};
}

const initialState: DashletsStorageState = {
    dashlets: {}
};

interface PutInDashletStorageAction {
    dashletId: number;
    key: string;
    value: any;
}

const dashletStorageSlice = createSlice(({
    name: "dashletsStorage",
    initialState,
    reducers: {
        putInDashletStorage: {
            reducer(state, action: PayloadAction<PutInDashletStorageAction>) {
                const {dashletId, key, value} = action.payload;

                // @ts-ignore
                state.dashlets[dashletId] = {
                    // @ts-ignore
                    ...state.dashlets[dashletId],
                    [key]: value
                }
            },
            prepare(dashletId, key, value) {
                return {
                    payload: {dashletId, key, value}
                }
            }
        }
    }
}));

export const { putInDashletStorage } = dashletStorageSlice.actions;

export default dashletStorageSlice.reducer;

export const selectDashletStorageValue = (state: any, dashletId: number, key: string) =>
    state.dashletsStorage.dashlets[dashletId] && state.dashletsStorage.dashlets[dashletId][key];