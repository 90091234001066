import React from "react";
import SnowflakeDatabaseReportRecommendationsSection
    from "../../types/model/report/SnowflakeDatabaseReportRecommendationsSection";
import SectionHeader from "../atomic/SectionHeader";
import SectionContainer from "../atomic/SectionContainer";
import {Table, TableBody, TableHead, TableRow} from "@material-ui/core";
import HeaderTableCell from "../atomic/table/HeaderTableCell";
import StringTableCell from "../atomic/table/StringTableCell";
import DecimalCurrencyTableCell from "../atomic/table/DecimalCurrencyTableCell";
import DecimalNumberTableCell from "../atomic/table/DecimalNumberTableCell";
import NumberTableCell from "../atomic/table/NumberTableCell";
import BooleanTableCell from "../atomic/table/BooleanTableCell";
import ActionTableCell from "../atomic/table/ActionTableCell";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = makeStyles({
    tableContainer: {
        width: "100%",
        maxHeight: 400,
        boxShadow: "none",
        overflowX: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f0f0f0',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f0f0f0',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
        },
    },
});

interface RecommendationsSectionProps {
    title: string;
    data: SnowflakeDatabaseReportRecommendationsSection;
    showRecommendations: (name: string, entityType: string, assistanceType: string) => void;
    showObservations: (name: string, entityType: string, assistanceType: string) => void;
}

const RecommendationsSection: React.FC<RecommendationsSectionProps> = ({
                                                                           title,
                                                                           data,
                                                                           showRecommendations,
                                                                           showObservations
                                                                       }) => {
    const classes = useStyles();

    return (
        <SectionContainer>
            <SectionHeader>{title}</SectionHeader>
                <TableContainer className={classes.tableContainer}>
                    <Table size="small" stickyHeader >
                        <TableHead>
                            <TableRow>
                                <HeaderTableCell>Warehouse name</HeaderTableCell>
                                <HeaderTableCell>Observations</HeaderTableCell>
                                <HeaderTableCell>Recommendations</HeaderTableCell>
                                <HeaderTableCell>WH Size</HeaderTableCell>
                                <HeaderTableCell>WH Cluster Size</HeaderTableCell>
                                <HeaderTableCell>Scaling Policy</HeaderTableCell>
                                <HeaderTableCell>Auto Suspend (s)</HeaderTableCell>
                                <HeaderTableCell>Credits Used</HeaderTableCell>
                                <HeaderTableCell>Warehouse Cost ($)</HeaderTableCell>
                                <HeaderTableCell>Warehouse Utilization (%)</HeaderTableCell>
                                <HeaderTableCell>Idle Credits</HeaderTableCell>
                                <HeaderTableCell>Avg. Queued Overload Time (s)</HeaderTableCell>
                                <HeaderTableCell>Total Remote Spillage (GB)</HeaderTableCell>
                                <HeaderTableCell>Query Accl. Enabled</HeaderTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.rows.map((row, index) => (
                                <TableRow key={`rec-table-row-${index}`}>
                                    <StringTableCell>{row.warehouseName}</StringTableCell>
                                    <ActionTableCell
                                        title="Insight"
                                        onClick={() => showObservations(row.warehouseName, "Warehouse", "Observations")}
                                    />
                                    <ActionTableCell
                                        title="Insight"
                                        onClick={() => showRecommendations(row.warehouseName, "Warehouse", "Recommendations")}
                                    />
                                    <StringTableCell>{row.whSize}</StringTableCell>
                                    <StringTableCell>{row.whClusterSize}</StringTableCell>
                                    <StringTableCell>{row.scalingPolicy}</StringTableCell>
                                    <NumberTableCell>{row.autoSuspend}</NumberTableCell>
                                    <DecimalNumberTableCell>{row.creditsUsed}</DecimalNumberTableCell>
                                    <DecimalCurrencyTableCell>{row.warehouseCost}</DecimalCurrencyTableCell>
                                    <NumberTableCell>{row.warehouseUtilization}</NumberTableCell>
                                    <NumberTableCell>{row.idleCredits}</NumberTableCell>
                                    <DecimalNumberTableCell>{row.avgQueuedOverloadTime}</DecimalNumberTableCell>
                                    <DecimalNumberTableCell>{row.totalRemoteSpillageGb}</DecimalNumberTableCell>
                                    <BooleanTableCell>{row.queueAcclEnabled}</BooleanTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        </SectionContainer>
    )
}

export default RecommendationsSection;
