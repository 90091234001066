import * as axios from 'axios';
import history from '../routes/history';
import {FORGOT_PASSWORD_FINISH_PATH, HOME_PATH, LOGIN_PATH} from '../routes/paths';
import { APP_CONF } from '../constants/appsConfig';
import AuthRestClient from "../api/rest/AuthRestClient";
import {routeTo} from "./routingActions";
import ERROR_MESSAGES from "../constants/errorMessages";
import {getErrorObject} from "../utils/errorUtils";
import {
  clearAuthentication,
  setForgotPasswordErrorMessage,
  setForgotPasswordInProgress,
  setForgotPasswordSuccess, setIsAuthenticated,
  setIsAuthenticatedLoaded,
  setIsLoginInProgress,
  setLoginErrorMessage,
  setLoginSuccess,
  setRegistrationErrorMessage,
  setRegistrationInProgress,
  setRegistrationSuccess
} from "../reducers/authSlice";
// import { setSecretKeyAndUser } from 'dashlets/HLCChartDashlet/actions';
import { setSecretKeyAndUserInHLC } from 'dashlets/HLCChartDashlet/webSocketClient';
import { toastr } from 'react-redux-toastr';

const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const getAuth = state => state.auth.auth;

export const authenticated = isAuthenticated => dispatch => {
  // dispatch(getUserInfo());
  dispatch(setIsAuthenticated(isAuthenticated));
};

export const loadIsAuthenticated = () => (dispatch, getState) => {
  if (getAuth(getState())) {
    AuthRestClient.check()
        .then(() => dispatch(authenticated(true)))
        .catch((error) => {
          console.error(error);
          dispatch(authenticated(false));
        });
  } else {
    dispatch(setIsAuthenticatedLoaded(true));
  }
};

export const logout = () => dispatch =>
    dispatch(logoutHLC_PEI())

// export const logoutUserAsNotRegisteredInBackend = () => dispatch => {
//   toastr.error("Logging out", "Try to login again.");
//   setTimeout(() => dispatch(logout()), 2000);
// }

/* Login page */
export const login = userDetails => dispatch => {
  if (!userDetails) {
    dispatch(setLoginErrorMessage("E-mail and Password are required."));
    return;
  }

  userDetails.username = userDetails.username.trim();
  userDetails.password = userDetails.password.trim();
  if (!userDetails.username || !userDetails.password) {
    dispatch(setLoginErrorMessage("E-mail and Password are required."));
    return;
  }

  if (!validateEmail(userDetails.username)) {
    dispatch(setLoginErrorMessage("E-mail is not valid."));
    return;
  }

  dispatch(setIsLoginInProgress());

  // AuthRestClient.login(userDetails)
  //     .then(resultObject => dispatch(loginHLC_PEI(userDetails, resultObject)))
  //     .catch(error => {
  //       if (getErrorObject(error).status === 401) {
  //         dispatch(setLoginErrorMessage('Wrong Email or Password'));
  //       } else {
  //         dispatch(setLoginErrorMessage(ERROR_MESSAGES.SERVER_ERROR));
  //       }
  //     });

  AuthRestClient.login(userDetails)
      .then(resultObject => {
        dispatch(loginHLC_PEI(userDetails, resultObject));
      })
      .catch(error => {
        if (error instanceof TypeError && error.message === "Failed to fetch") {
          dispatch(setLoginErrorMessage("Network error: Failed to fetch"));
        } else if (getErrorObject(error).status === 401) {
          dispatch(setLoginErrorMessage("Wrong Email or Password"));
        } else {
          dispatch(setLoginErrorMessage(ERROR_MESSAGES.SERVER_ERROR));
        }
      });
}

export const gotoLogin = () => dispatch => {
  history.push(LOGIN_PATH);
};

/* Registration */
export const register = registerUserDetails => dispatch => {
  registerUserDetails.name = registerUserDetails.name.trim();
  registerUserDetails.username = registerUserDetails.username.trim();
  registerUserDetails.password = registerUserDetails.password.trim();

  if (!registerUserDetails.name || !registerUserDetails.username || !registerUserDetails.password) {
    dispatch(setRegistrationErrorMessage("All fields are required."));
    return;
  }

  if (!validateEmail(registerUserDetails.username)) {
    dispatch(setRegistrationErrorMessage("E-mail is not valid."));
    return;
  }

  dispatch(setRegistrationInProgress());

  AuthRestClient.register(registerUserDetails)
      .then((resultObject) => dispatch(handleRegistrationResponse(resultObject)))
      .catch(error => {
        console.error(error);
        dispatch(setRegistrationErrorMessage(ERROR_MESSAGES.SERVER_ERROR));
      });
}

const handleRegistrationResponse = resultObject => (dispatch, getState) => {
  if (resultObject.success) {
    dispatch(registrationSuccess(resultObject.jwtAuthenticationResponse));
    // setSecretKeyAndUserInHLC(getState(), resultObject.cloudBackendKey, {username: resultObject.userId, secret: resultObject.secretKey}, resultObject.secretKey);
  } else {
    dispatch(setRegistrationErrorMessage(resultObject.message));
  }
}

const registrationSuccess = authObject => dispatch => {
  dispatch(setRegistrationSuccess(authObject))
  history.push(HOME_PATH);
};

/* Forgot Password */

export const forgotPassword = forgotPasswordData => dispatch => {
  forgotPasswordData.username = forgotPasswordData.username.trim();

  if (!forgotPasswordData.username) {
    dispatch(setForgotPasswordErrorMessage("E-mail is required."));
    return;
  }

  if (!validateEmail(forgotPasswordData.username)) {
    dispatch(setForgotPasswordErrorMessage("E-mail is not valid."));
    return;
  }

  dispatch(setForgotPasswordInProgress());

  AuthRestClient.forgotPassword(forgotPasswordData)
      .then((resultObject) => dispatch(handleForgotPasswordResponse(resultObject)))
      .catch((error) => {
        console.error(error);
        dispatch(setForgotPasswordErrorMessage(ERROR_MESSAGES.SERVER_ERROR))
      });
};

const handleForgotPasswordResponse = resultObject => dispatch =>{
  if (resultObject.success) {
    dispatch(forgotPasswordSuccess());
  } else {
    dispatch(setForgotPasswordErrorMessage(resultObject.message));
  }
}

export const forgotPasswordSuccess = () => dispatch => {
  history.push(FORGOT_PASSWORD_FINISH_PATH);
  dispatch(setForgotPasswordSuccess());
};

const loginHLC_PEI = (userDetails, resultObject) => {
  return (dispatch, getState) => {
    if (process.env.NODE_ENV === 'production') {
      try {
        loginHLC(userDetails);
      } finally {
        // do nothing
      }
      try {
        loginPEI(userDetails);
      } finally {
        // do nothing
      }
      // loginHLC(userDetails);
      // loginPEI(userDetails);

      dispatch(setLoginSuccess(resultObject.jwtAuthenticationResponse));

      // axios.all([loginHLC(userDetails), loginPEI(userDetails)])
      //   .then(axios.spread(function (hlcResponse) {
      //     dispatch(setLoginSuccess(auth));
      //   }));
    } else {
      dispatch(setLoginSuccess(resultObject.jwtAuthenticationResponse));
    }
    setSecretKeyAndUserInHLC(getState(), resultObject.cloudBackendKey, {username: resultObject.userId, secret: resultObject.secretKey, id: resultObject.id}, resultObject.secretKey);
    dispatch(authenticated(true));
  }
};

const loginHLC = (userDetails) => {
  let formData = new FormData();
  formData.set('username', userDetails.username);
  formData.set('password', userDetails.password);
  formData.set('ajax', true);

  return axios.post(APP_CONF.HLC.API.LOGIN,
      formData,
      {
        headers: {'Content-Type': 'multipart/form-data' }
      })
      .catch(error => {
        console.warn(`Ignored error: ${error.response.status}`);
      });
};

const loginPEI = userDetails => {
  let formData = new FormData();
  formData.set('username', userDetails.username);
  formData.set('password', userDetails.password);
  formData.set('ajax', true);

  return axios.post(APP_CONF.PEI.API.LOGIN,
      formData,
      {
        headers: {'Content-Type': 'multipart/form-data' }
      })
      .catch(error => {
        console.warn(`Ignored error: ${error.response.status}`);
      });
};

const logoutHLC_PEI = () => dispatch => {
  if (process.env.NODE_ENV === 'production') {
    try {
      logoutHLC();
    } finally {
      // do nothing
    }
    try {
      logoutPEI();
    } finally {
      // do nothing
    }
    // logoutPEI();
    dispatch(clearAuthentication());
    // axios.all([logoutHLC(), logoutPEI()])
    //     .then(axios.spread(function (hlcResponse) {
    //       dispatch(clearAuthentication())
    //     }))
  } else {
    dispatch(clearAuthentication());
  }};

// const logoutHLC = () => {
//   let formData = new FormData();
//
//   return axios.post(APP_CONF.HLC.API.LOGOUT,
//     formData,
//     {
//       headers: {'Content-Type': 'multipart/form-data' }
//     })
//
// };

const logoutHLC = () => {
  let formData = new FormData();

  return axios.post(APP_CONF.HLC.API.LOGOUT,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .catch(error => {
        console.warn(`Ignored error: ${error.response.status}`);
      });
};

const logoutPEI = () => {
  let formData = new FormData();

  return axios.post(APP_CONF.PEI.API.LOGOUT,
    formData,
    {
      headers: {'Content-Type': 'multipart/form-data' }
    })
      .catch(error => {
        console.warn(`Ignored error: ${error.response.status}`);
      });

};

export const changePassword = password => dispatch =>
    AuthRestClient.changePassword(password)
        .then(() => {
        })
        .catch(error => console.error(error))
        .finally(() => dispatch(routeTo(HOME_PATH)));