import {BASE_API_URL} from "../../config";
import CommonCrudRestClient from "./CommonCrudRestClient";

class DashletRestClient extends CommonCrudRestClient {

    async load(id) {
        return await super.load(id).then(this.parseJsonFields);
    }

    async create(dashlet) {
        const data = this.serializeJsonFields(dashlet);
        return await super.create(data).then(this.parseJsonFields);
    }

    async createDashletBatch(dashlets) {
        const data = dashlets.map(dashlet => this.serializeJsonFields(dashlet));
        return await this.post('/dashletBatch', data)
            .then(resultObject => {
            if (Array.isArray(resultObject)) {
                return resultObject.map(this.parseJsonFields);
            } else {
                return this.parseJsonFields(resultObject);
            }
        });
    }

    async update(id, dashlet) {
        const data = this.serializeJsonFields(dashlet);
        return await super.update(id, data).then(this.parseJsonFields);
    }

    async updateConfig(id, config) {
        const data = {
            dashletId: id,
            config: JSON.stringify(config)
        };
        return await this.post('/updateConfig', data)
            .then(resultObject => {
                resultObject.settings = JSON.parse(resultObject.config);
                return resultObject;
            });
    }

    async updateSettings(id, settings) {
        const data = {
            dashletId: id,
            settings: JSON.stringify(settings)
        };
        return await this.post('/updateSettings', data)
            .then(resultObject => {
                resultObject.settings = JSON.parse(resultObject.settings);
                return resultObject;
            });
    }

    async updateTimeRange(id, timeRange) {
        const data = {
            dashletId: id,
            timeRange: JSON.stringify(timeRange)
        };
        return await this.post('/updateTimeRange', data)
            .then(resultObject => {
                resultObject.timeRange = JSON.parse(resultObject.timeRange);
                return resultObject;
            });
    }

    async updateTimeRangeAndSettings(id, timeRange, settings) {
        const data = {
            dashletId: id,
            timeRangeJson: JSON.stringify(timeRange),
            settingsJson: JSON.stringify(settings)
        };
        return await this.post('/updateTimeRangeAndSettings', data)
            .then(resultObject => {
                resultObject.timeRange = JSON.parse(resultObject.timeRangeJson);
                resultObject.settings = JSON.parse(resultObject.settingsJson);
                return resultObject;
            });
    }

    parseJsonFields(dashlet) {
        dashlet.config = JSON.parse(dashlet.config);
        dashlet.settings = JSON.parse(dashlet.settings);
        dashlet.timeRange = JSON.parse(dashlet.timeRange);
        return dashlet;
    }

    serializeJsonFields(dashlet) {
        return {
            ...dashlet,
            config: JSON.stringify(dashlet.config),
            settings: JSON.stringify(dashlet.settings),
            timeRange: JSON.stringify(dashlet.timeRange)
        }
    }
}

export default new DashletRestClient(`${BASE_API_URL}/dashboard/groups/dashboards/dashlets`);