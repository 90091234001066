import {publish} from 'api/websocket/hlc/hlcCommonWebSocketClient';

import {
    WEB_SOCKET_ACTION_PREFIX,
    WEB_SOCKET_SUBSCR_PREFIX
} from "constants/webSocketConstants";
import GenerateSnowflakeFinOpsReportStompFrame from "./types/payload/GenerateSnowflakeFinOpsReportStompFrame";
import SnowflakeFinOpsReportAssistantStompFrame from "./types/payload/SnowflakeFinOpsReportAssistantStompFrame";

// Load report names
export const SNOWFLAKE_DATABASE_REPORT_GENERATE_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.dbreport.snowflake.generate`;
export const SNOWFLAKE_DATABASE_REPORT_GENERATE_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.dbreport.snowflake`;

export const generateSnowflakeDatabaseReport = (appState: any, backendKey: string, payload: GenerateSnowflakeFinOpsReportStompFrame) =>
    publish(appState, backendKey, SNOWFLAKE_DATABASE_REPORT_GENERATE_ACTION, payload);

//
export const SNOWFLAKE_FIN_OPS_REPORT_EXECUTE_ASSISTANT_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.dbreport.snowflake.explain.execute`;
export const SNOWFLAKE_DATABASE_REPORT_ASSISTANT_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.dbreport.snowflake.explain`;

export const executeSnowflakeFinOpsReportAssistant = (appState: any, backendKey: string, payload: SnowflakeFinOpsReportAssistantStompFrame) =>
    publish(appState, backendKey, SNOWFLAKE_FIN_OPS_REPORT_EXECUTE_ASSISTANT_ACTION, payload);
