import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import SubmitButton from "components/common/Button/SubmitButton";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import UseAnomalySpikeIntegration from "types/model/settings/UseAnomalySpikeIntegration";
import { MenuItem } from "@material-ui/core";
import Select from "components/common/Select/Select";


interface UseAnomalySpikeIntegrationsProps {
    integration: UseAnomalySpikeIntegration;
    onSave: (integration: UseAnomalySpikeIntegration) => void;
}

const DROPDOWN_VALUES = ["ZScore", "LOF", "IForest", "MAD", "IQR", "Anomaly Detection"];

const UseAnomalySpikeSection: React.FC<UseAnomalySpikeIntegrationsProps> = ({
    onSave,
    ...props
}) => {
    const { integration, handleChangeProperty }
        = useIntegrationSectionState(props.integration);
    const { useAnomalySpike, algorithmName} = integration as UseAnomalySpikeIntegration;

    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{ marginTop: "8px", marginBottom: "8px" }}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={4} style={{ textAlign: "left" }}>
                        <InputLabel htmlFor="useAnomalySpike">Use AI Anomaly Detection</InputLabel>
                    </Grid>
                    <Grid item xs={2}>
                        <Switch checked={useAnomalySpike}
                            onChange={(event:any) => handleChangeProperty(event.target.name, event.target.checked)}
                            name="useAnomalySpike"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginTop: '5px' }}>
                    <Grid item xs={4} style={{ textAlign: "left" }}>
                        <InputLabel htmlFor="algorithmName">AI Anomaly Detection Models</InputLabel>
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            disabled={!useAnomalySpike}
                            name="algorithmName"
                            value={algorithmName || "ZScore"}
                            onChange={(event:any) => handleChangeProperty(event.target.name, event.target.value)}
                        >
                            {DROPDOWN_VALUES.map((method) => (
                                <MenuItem key={method} value={method}>
                                    {method}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={6}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        {/*@ts-ignore*/}
                        <SubmitButton onClick={() => onSave(integration)}>Save</SubmitButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default UseAnomalySpikeSection;
