import React from "react";
import JobInfo from "types/model/infrastructure/JobInfo";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

interface JobsTableProps {
    rows: JobInfo[];
    pauseAction: (jobId: string, jobGroupName: string, jobName: string) => void;
    resumeAction: (jobId: string, jobGroupName: string, jobName: string) => void;
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        overflow: 'hidden',
    },
    container: {
        maxHeight: 600,
        overflowX: 'auto',
    },
    table: {
        width: '100%',
        tableLayout: 'auto', 
    },
    tableCell: {
        wordWrap: 'break-word',
        wordBreak: 'break-word', 
        whiteSpace: 'normal',
        padding: '12px',
        maxWidth: '200px', 
    },

    '@media (max-width: 600px)': {
        tableCell: {
            padding: '8px', 
            fontSize: '12px', 
            maxWidth: '120px', 
        },
        table: {
            tableLayout: 'auto', 
        },
        container: {
            overflowX: 'auto', 
            maxWidth: '100%',
        },
    },

    '@media (min-width: 1024px)': {
        tableCell: {
            padding: '16px',
            fontSize: '14px', 
        },
    },
});

const JobsTable: React.FC<JobsTableProps> = ({
                                                 rows,
                                                 pauseAction,
                                                 resumeAction
                                             }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table className={classes.table} stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableCell}>Name</TableCell>
                            <TableCell className={classes.tableCell}>Group</TableCell>
                            {/*<TableCell>Description</TableCell>*/}
                            <TableCell align="right" className={classes.tableCell}>Interval</TableCell>
                            <TableCell className={classes.tableCell}>Status</TableCell>
                            <TableCell className={classes.tableCell}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: JobInfo) => {
                            return (
                                <TableRow hover key={row.id}>
                                    <TableCell className={classes.tableCell}>{row.jobName}</TableCell>
                                    <TableCell className={classes.tableCell}>{row.groupName}</TableCell>
                                    {/*<TableCell>{row.description}</TableCell>*/}
                                    <TableCell align="right" className={classes.tableCell}>{row.interval}</TableCell>
                                    <TableCell className={`${classes.tableCell} `}>{row.jobStatus}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            disabled={row.jobStatus === "PAUSED"}
                                            onClick={() => pauseAction(row.id, row.groupName, row.jobName)}
                                        >
                                            <PauseIcon fontSize="small"/>
                                        </IconButton>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            disabled={!(row.jobStatus === "PAUSED")}
                                            onClick={() => resumeAction(row.id, row.groupName, row.jobName)}
                                        >
                                            <PlayArrowIcon fontSize="small"/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default JobsTable;
