import React, {useEffect, useState} from 'react';
import {filter as _filter} from "lodash";
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import {listTargetNames} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import DashletConfigSelectTargetNamesModal from "./DashletConfigSelectTargetNamesModal";
import {LIST_TARGET_NAMES_SUBSCRIPTION} from "../../hlcDashletWebSocketClient";
import ListDashletTargetConfigurationOptionsStompResponse
    from "../../types/ListDashletTargetConfigurationOptionsStompResponse";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {selectTargetOptions, setDashletOptionsProperty} from 'reducers/dashboard/createDashletSlice';
import {toastr} from 'react-redux-toastr';
import DashletTargetConfigurationOption from "../../types/DashletTargetConfigurationOption";
// import { logoutUserAsNotRegisteredInBackend } from 'actions/authActions';

interface DashletConfigSelectTargetNamesProps {
    multi?: boolean;
    backendKey?: string;
    targetNames: string[];
    filterDatabasePlatforms?: string[];
    onSubmitTargetNames: (targetNames: string[]) => void;
}

const DashletConfigSelectTargetNames: React.FC<DashletConfigSelectTargetNamesProps> = ({
                                                                                           multi,
                                                                                           backendKey,
                                                                                           targetNames,
                                                                                           filterDatabasePlatforms,
                                                                                           onSubmitTargetNames
                                                                                       }) => {
    const dispatch = useDispatch();
    const [isSelectTargetsModalOpened, setIsSelectTargetsModalOpened] = useState<boolean>(false);
    // const [targetOptions, setTargetOptions] = useState<DashletTargetConfigurationOption[]>([])
    // const [isLoadingTargetNames, setIsLoadingTargetNames] = useState<boolean>(false)
    // const isLoadingTargetNames = useSelector(selectIsLoadingTargetNames);
    const allTargetOptions: DashletTargetConfigurationOption[] = useSelector(selectTargetOptions);

    // Filter options by filterDatabasePlatforms property if is set
    const targetOptions = filterDatabasePlatforms ? _filter(allTargetOptions, (opt: DashletTargetConfigurationOption) =>
        filterDatabasePlatforms.includes(opt.databasePlatform)) : allTargetOptions;


    const onSubmitTargetNamesHandler = (targetNames: string[]) => {
        onSubmitTargetNames(targetNames);
        setIsSelectTargetsModalOpened(false);
    }

    const handleListTargetNamesStompResponse = (response: ListDashletTargetConfigurationOptionsStompResponse) => {
        if (!response.hasErrors) {
            dispatch(setDashletOptionsProperty({propertyName: "targetOptions", propertyValue: response.options}))
            // setTargetOptions(response.options);
        } else {
            console.log(response.errorMessage); //todo: handle error
            // if (response.errorMessage && response.errorMessage.includes("User not registered")) {
            //     toastr.error("User not found", response.errorMessage);
            //     dispatch(logoutUserAsNotRegisteredInBackend());
            // }
        }
        // dispatch(toggeleIsLoadingTargetNames(false));
        // setIsLoadingTargetNames(false);
    };

    // list targets from the Backend
    useEffect(() => {
        if (backendKey) {
            // load target names
            // dispatch(toggeleIsLoadingTargetNames(true));
            // setIsLoadingTargetNames(true);
            dispatch(setDashletOptionsProperty({propertyName: "targetOptions", propertyValue: []}))
            dispatch(listTargetNames(backendKey));
        }
    }, [backendKey]);

    return <React.Fragment>
        <FormControl fullWidth margin="dense">
            <Button
                size="small"
                onClick={() => {
                    if (targetOptions) {
                        setIsSelectTargetsModalOpened(true);
                    } else {
                        toastr.warning("No Targets", "Selected backend has no Targets available.");
                    }
                }}>
                {multi ? `Select Targets (${targetNames ? targetNames.length : 0})` : `Select Target (${targetNames.length > 0 ? targetNames[0] : ''})`}
            </Button>
        </FormControl>
        {targetOptions && <DashletConfigSelectTargetNamesModal
            open={isSelectTargetsModalOpened}
            multi={multi}
            targetOptions={targetOptions}
            targetNames={targetNames}
            onSubmit={onSubmitTargetNamesHandler}
            onClose={() => setIsSelectTargetsModalOpened(false)}
        />}
        <HLCUserSessionWebSocketSubscription
            subscriptionDestination={LIST_TARGET_NAMES_SUBSCRIPTION}
            handleMessage={handleListTargetNamesStompResponse}
        />
    </React.Fragment>;
}

export default DashletConfigSelectTargetNames;